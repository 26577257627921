import request from '@/utils/request'

/**
 * 查询项目列表
 */
export const getProjectList = async data =>
  request({ url: '/sysTenantRenovation/list', method: 'GET', data })
/**
 * 根据id查询项目详情
 */
export const getProjectById = async data =>
  request({ url: 'project/getById', method: 'POST', data })

/**
 * 新增项目
 */
export const addProject = async data =>
  request({ url: '/sysTenantRenovation/addOrEdit', method: 'POST', data })

/**
 * 编辑
 */
export const editProject = async data =>
  request({ url: '/sysTenantRenovation/addOrEdit', method: 'POST', data })

/**
 * 删除
 */
export const delProject = async data =>
  request({ url: '/sysTenantRenovation/deleteById', method: 'DELETE',  params:data })

/**
 * 查询项目模板
 */
export const getModelList = async (data) =>
  request({ url: '/sysTenantRenovation/list', method: 'GET', params: data })

/**
 * 封面上传
 */
export const uploadCover = async data =>
  request({ url: '/sys/common/upload', method: 'POST', data })

/**
 * 生成小程序码
 */
export const createWXcode = async data =>
  request({ url: 'project/getWXQr', method: 'POST', data })
